import React from "react";
import styled from "styled-components";

function TableTab({ style, list, tab, setTab, onClick }) {
  return (
    <>
      <TableTabWrapper style={{ ...style }}>
        {list.map((item, index) => {
          return (
            <Tab
              key={index}
              style={{
                color: `${tab === item.type ? "#27BEFF" : ""}`,
                borderBottom: `${tab === item.type ? "2px solid #27BEFF" : ""}`,
              }}
              onClick={() => {
                setTab(item.type);
                onClick();
              }}
            >
              {item.title}
            </Tab>
          );
        })}
      </TableTabWrapper>
    </>
  );
}

export default TableTab;

const TableTabWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Tab = styled.div.attrs((props) => {})`
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 10px;
  color: #adb4ba;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px; /* 162.5% */
  letter-spacing: -0.054px;
  cursor: pointer;
`;
