import api from "./api.js";

export default class VisitorApi {
  static async GetMemberCount() {
    return await api.get(`admin/member/count`);
  }

  static async GetVisitorCount(data) {
    return await api.get(`admin/visitors`, data);
  }
}
