function nullCheck(value) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    value === "null" ||
    typeof value === "undefined" ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return true;
  }
  return false;
}

function isVideo(filename) {
  var _fileLen = filename?.length;

  /**
   * lastIndexOf('.')
   * 뒤에서부터 '.'의 위치를 찾기위한 함수
   * 검색 문자의 위치를 반환한다.
   * 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
   */
  var _lastDot = filename?.lastIndexOf(".");

  // 확장자 명만 추출한 후 소문자로 변경
  var _fileExt = filename?.substring(_lastDot, _fileLen).toLowerCase();

  if (
    _fileExt === ".png" ||
    _fileExt === ".jpg" ||
    _fileExt === ".jpeg" ||
    _fileExt === ".jfif" ||
    _fileExt === ".pjp"
  ) {
    return false;
  } else return true;
}

export const videoExtension = [".mp4", ".webm", ".mov"];

export { nullCheck, isVideo };
