import api from "./api.js";

export default class AlarmApi {
  static async Get(data) {
    return await api.get(`admin/alarms`, data);
  }

  static async GetById(id) {
    return await api.get(`admin/alarm/${id}`);
  }

  static async Del(id) {
    return await api.del(`admin/alarm/${id}`);
  }

  static async DeleteChecked(queryString) {
    return await api.del(`admin/alarms/?${queryString}`);
  }

  static async Update(id, data) {
    return await api.put(`admin/alarm/${id}`, data);
  }

  static async Add(data) {
    return await api.post(`admin/alarm`, data);
  }
}
