import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import TermsApi from "../../../api/termsApi";

function TermsDetail(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await TermsApi.GetById(id)).data.data;
      setData({
        ...response,
        content: response.content.replaceAll(/\r\n|\r|\n/gi, "<br/>"),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <EditBtn
                onClick={() => {
                  navigate(route.service_terms_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "244px" }}>
            <DetailPageTitle>약관 상세</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>약관명</DetailFormTitle>
                <DetailFormText>{data.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>약관 내용</DetailFormTitle>
                <DetailFormText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
}

export default TermsDetail;
