import { toast } from "react-toastify";
import CommonApi from "./../api/commonApi";
import { errorHandler } from "./error-handler";

export async function fileDownload(serverFileName, originFileName) {
  try {
    const data = {
      serverFileName: serverFileName,
      originalFileName: originFileName,
    };

    const response = await CommonApi.FileDownload(data);
    download(response, originFileName);
  } catch (error) {
    errorHandler(error);
  }
}

function download(response, filename) {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  link.style.cssText = "display:none";
  document.body.appendChild(link);
  link.click();
  link.remove();
}

//사진 미리보기 있을 때 사용
export function fileUpload(e, setFileUrl, setFile) {
  const file = e.target.files[0];
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = (fileData) => {
    setFileUrl(fileData.target.result);
    setFile(file);
  };
  e.target.value = "";
}

export function multiFileUpload(e, state, setState, changeKey, imgArr) {
  const file = e.target.files[0];
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = (fileData) => {
    const copyArr = [...imgArr];
    copyArr.push({ fileUrl: fileData.target.result, file: file });
    setState({
      ...state,
      [changeKey]: copyArr,
    });
  };
  e.target.value = "";
}

export const deleteFile = (state, setState) => {
  setState({ ...state, file: "", fileUrl: "" });
};

export const deleteMultiFile = (state, setState, index, changeKey, imgArr) => {
  const copyArr = [...imgArr];
  copyArr.splice(index, 1);
  setState({
    ...state,
    [changeKey]: copyArr,
  });
};

//pdf 업로드
export function pdfUpload(e, setState) {
  const file = e.target.files[0];

  if (!file.type.includes("pdf")) {
    e.target.value = "";
    return toast("PDF 파일만 업로드 가능합니다");
  }

  setState(file);
  e.target.value = "";
}

//엑셀 다운로드
export const excelDownload = (response, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  );
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", `${fileName}`);
  document.body.appendChild(link);

  link.click();
  link.remove();
};
