import React from "react";
import styled from "styled-components";

function DelBtn({ style, text, onClick }) {
  return (
    <>
      <DelBtnWrapper style={{ ...style }} onClick={onClick}>
        {text ?? "삭제"}
      </DelBtnWrapper>
    </>
  );
}

export default DelBtn;

const DelBtnWrapper = styled.button.attrs((props) => {})`
  display: flex;
  height: 42px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: 1px solid #808991;
  background: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  color: #636c73;
  cursor: pointer;
`;
