import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MainLayout from "../../component/atoms/layout/mainLayout";
import PretendardText from "../../component/atoms/text/pretendardText";
import MainGraph from "../../component/molecules/mainGraph";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { errorHandler } from "../../util/error-handler";
import { toastMsg } from "../../util/toastMsg";
import VisitorApi from "../../api/visitorApi";
// import CountApi from "../../api/countApi";

function Home(props) {
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState({
    totalCount: 0,
    joinCount: 0,
    leaveCount: 0,
    parentCount: 0,
    parentJoinCount: 0,
    parentLeaveCount: 0,
    childCount: 0,
    childJoinCount: 0,
    childLeaveCount: 0,
  });

  const [parentVisit, setParentVisit] = useState([]);
  const [parentGraphLast, setParentGraphLast] = useState(false);
  const [parentGraphMax, setParentGraphMax] = useState(0);
  const [parentCurrentPage, setParentCurrentPage] = useState(0);
  const [parentvisitLine, setParentVisitLine] = useState([]);

  const [childVisit, setChildVisit] = useState([]);
  const [childGraphLast, setChildGraphLast] = useState(false);
  const [childGraphMax, setChildGraphMax] = useState(0);
  const [childCurrentPage, setChildCurrentPage] = useState(0);
  const [childVisitLine, setChildVisitLine] = useState([]);

  useEffect(() => {
    getMemberCount();
  }, []);

  const getMemberCount = async () => {
    try {
      const count = (await VisitorApi.GetMemberCount()).data.data;
      setTotalCount(count);
    } catch (error) {
      toast(errorHandler(error));
    }
  };

  useEffect(() => {
    getParentCount();
    getChildCount();
  }, [parentCurrentPage, childCurrentPage]);

  const getParentCount = async () => {
    try {
      const data = {
        page: parentCurrentPage,
        size: 10,
        memberType: "PARENT",
      };
      const list = (await VisitorApi.GetVisitorCount(data)).data.data;

      setParentGraphLast(list.last);
      setParentVisit(list.content);
    } catch (error) {
      toast(toastMsg.errorText_500);
    }
  };

  useEffect(() => {
    let line = [];
    let max = 0;
    for (let i = 0; i < parentVisit.length; i++) {
      if (max <= parentVisit[i].count) {
        max = parentVisit[i].count;
      }
    }
    setParentGraphMax(max);
    let per = max / 3;
    for (let i = 0; i < 3; i++) {
      line.push(Math.round(max - per * i));
    }
    setParentVisitLine(line);
  }, [parentVisit]);

  const getChildCount = async () => {
    try {
      const data = {
        page: childCurrentPage,
        size: 10,
        memberType: "CHILD",
      };
      const list = (await VisitorApi.GetVisitorCount(data)).data.data;

      setChildGraphLast(list.last);
      setChildVisit(list.content);
    } catch (error) {
      toast(toastMsg.errorText_500);
    }
  };

  useEffect(() => {
    let line = [];
    let max = 0;
    for (let i = 0; i < childVisit.length; i++) {
      if (max <= childVisit[i].count) {
        max = childVisit[i].count;
      }
    }
    setChildGraphMax(max);
    let per = max / 3;
    for (let i = 0; i < 3; i++) {
      line.push(Math.round(max - per * i));
    }
    setChildVisitLine(line);
  }, [childVisit]);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <HomeWrapper>
          <HomeHeader>
            <div>
              <PretendardText
                style={{
                  color: "#808991",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "34px",
                  letterSpacing: "-0.06px",
                }}
              >
                Welcome!
              </PretendardText>
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: "40px",
                  fontWeight: "700",
                  lineHeight: "56px",
                  letterSpacing: "-0.28px",
                }}
              >
                안녕하세요. 관리자님 :)
              </PretendardText>
            </div>
            <TotalCountWrapper>
              <TotalCountInfo>
                <HomePretendardText style={{ color: "#636C73" }}>전체 사용자 수</HomePretendardText>
                <img src="/assets/admin/icons/ico_divider.svg" alt="" />
                <HomePretendardText style={{ color: "#636C73" }}>
                  {totalCount?.totalCount}명
                </HomePretendardText>
              </TotalCountInfo>
              <TotalCountInfo>
                <HomePretendardText style={{ color: "#636C73" }}>
                  회원(가입/탈퇴)
                </HomePretendardText>
                <img src="/assets/admin/icons/ico_divider.svg" alt="" />
                <HomePretendardText style={{ color: "#636C73" }}>
                  {totalCount?.joinCount}/{totalCount?.leaveCount}
                </HomePretendardText>
              </TotalCountInfo>
            </TotalCountWrapper>
          </HomeHeader>
          <TotalCountWrapper style={{ marginBottom: "20px", justifyContent: "space-between" }}>
            <TotalCountInfo>
              <HomePretendardText style={{ color: "#636C73" }}>
                보호자 유저 사용자 수
              </HomePretendardText>
              <img src="/assets/admin/icons/ico_divider.svg" alt="" />
              <HomePretendardText style={{ color: "#636C73" }}>
                {totalCount?.parentCount}명
              </HomePretendardText>
            </TotalCountInfo>
            <TotalCountInfo>
              <HomePretendardText style={{ color: "#636C73" }}>
                자녀 유저 사용자 수
              </HomePretendardText>
              <img src="/assets/admin/icons/ico_divider.svg" alt="" />
              <HomePretendardText style={{ color: "#636C73" }}>
                {totalCount?.childCount}명
              </HomePretendardText>
            </TotalCountInfo>
            <TotalCountInfo>
              <HomePretendardText style={{ color: "#636C73" }}>
                보호자 유저(가입/탈퇴)
              </HomePretendardText>
              <img src="/assets/admin/icons/ico_divider.svg" alt="" />
              <HomePretendardText style={{ color: "#636C73" }}>
                {totalCount?.parentJoinCount}/{totalCount?.parentLeaveCount}
              </HomePretendardText>
            </TotalCountInfo>
            <TotalCountInfo>
              <HomePretendardText style={{ color: "#636C73" }}>
                자녀 유저(가입/탈퇴)
              </HomePretendardText>
              <img src="/assets/admin/icons/ico_divider.svg" alt="" />
              <HomePretendardText style={{ color: "#636C73" }}>
                {totalCount?.childJoinCount}/{totalCount?.childLeaveCount}
              </HomePretendardText>
            </TotalCountInfo>
          </TotalCountWrapper>
          <HomePretendardText style={{ marginBottom: "10px" }}>
            보호자 유저 방문자 통계
          </HomePretendardText>
          <MainGraph
            visit={parentVisit}
            graphMax={parentGraphMax}
            currentPage={parentCurrentPage}
            graphLast={parentGraphLast}
            setCurrentPage={setParentCurrentPage}
            visitLine={parentvisitLine}
          />
          <HomePretendardText style={{ marginBottom: "10px" }}>
            자녀 유저 방문자 통계
          </HomePretendardText>
          <MainGraph
            style={{ marginBottom: "100px" }}
            visit={childVisit}
            graphMax={childGraphMax}
            currentPage={childCurrentPage}
            graphLast={childGraphLast}
            setCurrentPage={setChildCurrentPage}
            visitLine={childVisitLine}
          />
        </HomeWrapper>
      </MainLayout>
    </>
  );
}

export default Home;

const HomeWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  margin-top: 122px;
`;

const HomeHeader = styled.div.attrs((props) => {})`
  width: 1044px;
  height: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TotalCountWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  padding: 14px 40px;
  gap: 50px;
  background: #f2f4f5;
  border-radius: 6px;
`;

const TotalCountInfo = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const HomePretendardText = styled(PretendardText).attrs((props) => {})`
  color: #262c31;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
`;
