import api from "./api.js";

export default class MemberApi {
  static async Get(data) {
    return await api.get(`admin/commonMembers`, data);
  }

  static async GetById(id) {
    return await api.get(`admin/commonMember/${id}`);
  }

  static async GetChild(id) {
    return await api.get(`admin/commonMembers/${id}`);
  }

  static async Activate(id, data) {
    return await api.put(`admin/commonMember/activation/${id}`, data);
  }

  static async Del(id) {
    return await api.del(`admin/commonMember/${id}`);
  }

  static async Edit(id, data) {
    return await api.put(`admin/commonMember/${id}`, data);
  }
}
