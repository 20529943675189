import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import DelBtn from "../../../component/atoms/button/delBtn";
import AddBtn from "../../../component/atoms/button/addBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TermsApi from "../../../api/termsApi";

function Terms(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [list, setList] = useState([]);

  const [delIdList, setDelIdList] = useState([]);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };
      let list = (await TermsApi.Get(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      const idList = delIdList.map((item) => {
        return item.id;
      });

      const formData = new FormData();
      idList.map((item) => formData.append("idList", item));

      await TermsApi.DeleteChecked(formData);

      toast(toastMsg.delete);
      setDelModalOpen(false);
      setDelIdList([]);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>약관 관리</TableTitle>
          <TableTop>
            <TableCount text={"항목"} count={pagination.totalCount} />
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="737px" />
              <col width="139px" />
              <col width="77px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}></Th>
                <Th>
                  <ThText>약관 종류</ThText>
                </Th>
                <Th>
                  <ThText>마지막 수정일</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "20px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}></Td>
                    <Td>
                      <TdText>{item.title}</TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.service_terms_detail + `/${item.id}`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.service_terms}
            queryString={""}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Terms;
