import api from "./api.js";

export default class BaseApi {
  static async Get(data) {
    return await api.get(`common/baseInfo`, data);
  }
  static async Update(data) {
    return await api.put(`admin/baseInfo`, data);
  }
}
