import api from "./api.js";

export default class ManagerApi {
  static async Get(data) {
    return await api.get(`admin/managers`, data);
  }

  static async GetById(managerId) {
    return await api.get(`admin/manager/${managerId}`);
  }

  static async Update(managerId, data) {
    return await api.put(`admin/manager/${managerId}`, data);
  }

  static async Delete(managerId) {
    return await api.del(`admin/manager/${managerId}`);
  }

  static async DeleteChecked(data) {
    return await api.del(`admin/managers`, "", data);
  }

  static async Approve(managerId, data) {
    return await api.put(`admin/manager/approve/${managerId}`, data);
  }

  static async Activation(managerId, data) {
    return await api.put(`admin/manager/activation/${managerId}`, data);
  }

  static async Authority(managerId, data) {
    return await api.put(`admin/manager/authority/${managerId}`, data);
  }

  static async Login(data) {
    return await api.post(`common/manager/login`, data);
  }

  static async Join(data) {
    return await api.post(`common/manager`, data);
  }
}
