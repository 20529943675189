import React from "react";
import styled from "styled-components";
import PretendardText from "../text/pretendardText";

function PostInput({ placeholder, style, address, onClick }) {
  return (
    <>
      <PostInputWrapper
        style={{
          ...style,
        }}
        onClick={onClick}
      >
        <PretendardText
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "21.25px",
            color: address ? "#262c31" : "#808991",
          }}
        >
          {address ? address : placeholder}
        </PretendardText>
        <img src="/assets/admin/icons/ico_post_glass.svg" alt="" />
      </PostInputWrapper>
    </>
  );
}

export default PostInput;

const PostInputWrapper = styled.div.attrs((props) => {})`
  display: flex;
  width: 338px;
  height: 48px;
  padding: 13px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
  cursor: pointer;
`;
