import moment from "moment";
import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";

function MainGraph({ style, visit, graphMax, currentPage, graphLast, setCurrentPage, visitLine }) {
  return (
    <>
      <GraphWrapper style={style}>
        <img
          style={{ width: "28px", marginLeft: "24px", cursor: "pointer", zIndex: 2 }}
          src="/assets/admin/icons/ico_arrow_left.svg"
          alt=""
          onClick={() => {
            if (graphLast) {
              return;
            }
            setCurrentPage(currentPage + 1);
          }}
        />
        <GraphBox>
          {/* ----- 그래프 축 설정 부분 ----- */}
          <GraphAxisWrapper>
            {visitLine?.map((item, index) => {
              return (
                <GraphAxis key={index}>
                  <GraphAxisValue>{item}</GraphAxisValue>
                  <GraphLine></GraphLine>
                </GraphAxis>
              );
            })}
            <GraphAxis>
              <GraphAxisValue>0</GraphAxisValue>
              <GraphLine></GraphLine>
            </GraphAxis>
          </GraphAxisWrapper>

          {/* ----- 그래프 바 설정 부분 ----- */}
          <GraphBarWrapper>
            {visit?.map((item, index) => {
              let today = moment(item.createdAt).format("MM/DD");
              return (
                <GraphBarBox key={index}>
                  <GraphBarValue>
                    <GraphValue>{item.count}</GraphValue>
                    <GraphBar
                      style={{
                        height: `calc(183px - 183px * ${1 - item.count / graphMax})`,
                        backgroundColor: `${index % 2 === 0 ? "#27BEFF" : "#AF894F"}`,
                      }}
                    ></GraphBar>
                  </GraphBarValue>
                  <GraphDate>{today}</GraphDate>
                </GraphBarBox>
              );
            })}
          </GraphBarWrapper>
        </GraphBox>
        <img
          style={{
            width: "28px",
            marginRight: "24px",
            cursor: "pointer",
            transform: "rotate(180deg)",
          }}
          src="/assets/admin/icons/ico_arrow_left.svg"
          alt=""
          onClick={() => {
            if (currentPage <= 0) {
              return;
            }
            setCurrentPage(currentPage - 1);
          }}
        />
      </GraphWrapper>
    </>
  );
}

export default MainGraph;

const GraphWrapper = styled.div.attrs((props) => {})`
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  width: 1044px;
  height: 298px;
  position: relative;
  margin-bottom: 40px;
  display: flex;
`;

const GraphBox = styled.div.attrs((props) => {})`
  width: 1044px;
  height: 298px;
  position: relative;
`;

const GraphAxisWrapper = styled.div.attrs((props) => {})`
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

const GraphBarWrapper = styled.div.attrs((props) => {})`
  position: absolute;
  bottom: 8%;
  right: 12%;
  height: 180px;
  width: 846px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
`;

const GraphAxis = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
`;

const GraphLine = styled.div.attrs((props) => {})`
  width: 846px;
  border-bottom: 0.5px solid #d1d5d950;
`;

const GraphBarBox = styled.div.attrs((props) => {})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
  height: 180px;
`;

const GraphBarValue = styled.div.attrs((props) => {})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GraphBar = styled.div.attrs((props) => {})`
  display: flex;
  width: 22px;
  background: var(--key-blue, #0080ff);
  border-radius: 50px 50px 0px 0px;
`;

const GraphValue = styled(PretendardText).attrs((props) => {})`
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24.5px;
  text-align: center;
  color: var(--gray-6, #636c73);
  display: block;
  width: 70px;
  height: 25px;
`;

const GraphDate = styled(PretendardText).attrs((props) => {})`
  margin-top: 18px;
  display: block;
  width: 70px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20.25px; /* 144.643% */
  color: #636c73;
`;

const GraphAxisValue = styled(PretendardText).attrs((props) => {})`
  font-weight: 500;
  font-size: 13px;
  line-height: 18.75px;
  text-align: right;
  color: #b8bfc4;
  margin-right: 18px;
  display: block;
  width: 14px;
  height: 19px;
`;
