import api from "./api.js";

export default class AcademyApi {
  static async Get(data) {
    return await api.get(`admin/academies`, data);
  }

  static async GetById(id) {
    return await api.get(`admin/academy/${id}`);
  }

  static async GetMember(id, data) {
    return await api.get(`admin/academy/memberAcademies/${id}`, data);
  }

  static async GetMemberAcademy(id, data) {
    return await api.get(`admin/commonMember/memberAcademies/${id}`, data);
  }

  static async Edit(id, data) {
    return await api.put(`admin/academy/${id}`, data);
  }

  static async Add(data) {
    return await api.post(`admin/academy`, data);
  }

  static async getChildAcademy(id, data) {
    return await api.get(`admin/schedule/${id}`, data);
  }

  static async DeleteById(id) {
    return await api.del(`admin/academy/${id}`);
  }
}
