import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import { modalMsg } from "../../../util/modalMsg";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import LeaveApi from "../../../api/leave.api";

function Leave(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
      };
      let list = (await LeaveApi.GetList(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  // ----- 삭제
  const [delId, setDelId] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteOneFunc = async () => {
    try {
      await LeaveApi.DeleteById(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      setDelId(0);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>탈퇴 회원 관리</TableTitle>
          <TableTop>
            <TableCount text={"이용자"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.member_leave}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.member_leave}/1?keyword=${keyword}`, { replace: true });
                }}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="241px" />
              <col width="156px" />
              <col width="340px" />
              <col width="144px" />
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <ThText>구분</ThText>
                </Th>
                <Th>
                  <ThText>이메일</ThText>
                </Th>
                <Th>
                  <ThText>이름</ThText>
                </Th>
                <Th>
                  <ThText>탈퇴사유</ThText>
                </Th>
                <Th>
                  <ThText>탈퇴일</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "20px" }}>완전 삭제</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <TdText>{item.memberTypeTitle}</TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "181px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.email}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "97px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.name}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "290px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.leaveType === "ETC"
                          ? `${item.leaveTypeTitle} / ${item.reasonDetail}`
                          : item.leaveTypeTitle}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "20px",
                        }}
                      >
                        <TableDelBtn
                          onClick={() => {
                            setDelId(item.id);
                            setDelModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.member_leave}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>

        {delModalOpen ? (
          <Modal
            title={modalMsg.delete?.title}
            text={modalMsg.delete?.content}
            btnTitle={"삭제하기"}
            setModalOpen={setDelModalOpen}
            onClick={() => {
              deleteOneFunc();
            }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </>
  );
}

export default Leave;
