import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../../component/molecules/tableCount";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Checkbox from "../../../component/atoms/input/checkbox";
import Th from "../../../component/atoms/layout/table/th";
import Td from "../../../component/atoms/layout/table/td";
import Pagenation from "../../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import ThText from "../../../component/atoms/text/table/thText";
import TdText from "../../../component/atoms/text/table/tdText";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import { modalMsg } from "../../../util/modalMsg";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import MemberApi from "../../../api/memberApi";
import TableFilterManager from "../../../component/atoms/layout/table/tableFilterManager";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import TableDropDown from "../../../component/molecules/tableDropDown";
import { useRecoilValue } from "recoil";
import { memberFilterState } from "../../../recoil/memberFilter";
import ExcelApi from "../../../api/excelApi";
import { excelDownload } from "../../../util/file-management";
import PretendardText from "../../../component/atoms/text/pretendardText";

function Child(props) {
  const { page } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [delId, setDelId] = useState([]);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const [filterOpen, setFilterOpen] = useState(-1);
  const [activeId, setActiveId] = useState(null);
  const [activeModalOpen, setActiveModalOpen] = useState(false);
  const [deactiveModalOpen, setDeactiveModalOpen] = useState(false);

  const filterName = useRecoilValue(memberFilterState);

  const filterList = [
    { title: "전체", type: "NONE" },
    { title: "이름", type: "NAME" },
    { title: "이메일", type: "EMAIL" },
    { title: "성별", type: "GENDER" },
    { title: "생년월일", type: "BIRTH" },
    { title: "휴대폰번호", type: "PHONE_NUMBER" },
    { title: "주소", type: "ADDRESS" },
    { title: "등록 보호자", type: "PARENT" },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
        memberFilter: filterName.type,
        memberType: "CHILD",
      };
      let list = (await MemberApi.Get(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const activeFunc = async (activation) => {
    try {
      const data = {
        activation,
      };
      await MemberApi.Activate(activeId, data);
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActiveModalOpen(false);
      setDeactiveModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      await MemberApi.Del(delId);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  async function getExcel(isAll) {
    if (isAll) {
      await getExcelAll();
      setLoading(false);
    } else {
      await getExcelChecked();
      setLoading(false);
    }
  }

  const getExcelChecked = async () => {
    try {
      const filter = list
        .filter((item) => item.checked)
        .map((item02) => {
          return item02.id;
        });

      if (filter.length === 0) {
        toast("항목을 선택해주세요");
        return;
      }

      setLoading(true);
      const data = {
        idList: filter,
        isAll: false,
        memebrType: "CHILD",
      };

      const response = await ExcelApi.getExcel(data);
      excelDownload(response, "자녀 회원 관리.xlsx");
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelAll = async () => {
    setLoading(true);
    try {
      const data = {
        idList: [0],
        isAll: true,
        memebrType: "CHILD",
      };

      const response = await ExcelApi.getExcel(data);
      excelDownload(response, "자녀 회원 관리.xlsx");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-wrapper">
          <div className="loading-spinner-wrapper">
            <div className="loading-spinner">
              <img src="/assets/admin/icons/loading-spinner.svg" alt="" />
              <PretendardText style={{ fontSize: "14px", fontWeight: "700", color: "#fff" }}>
                잠시만 기다려주세요
              </PretendardText>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>자녀 회원 관리</TableTitle>
          <TableTop>
            <TableCount text={"이용자"} count={pagination.totalCount} />
            <TableSearchBtn>
              <TableDropDown setSearchParams={setSearchParams} filterList={filterList} />
              <SearchBar
                value={keyword}
                placeholder={
                  filterName.type === "GENDER"
                    ? "남성, 여성으로 입력하세요"
                    : filterName.type === "BIRTH"
                    ? "ex) 97/02/24"
                    : filterName.type === "PHONE_NUMBER"
                    ? "- 빼고 입력하세요"
                    : null
                }
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.member_child}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.member_child}/1?keyword=${keyword}`, { replace: true });
                }}
              />
              <ExcelBtn onSelectClick={() => getExcel(false)} onAllClick={() => getExcel(true)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="241px" />
              <col width="156px" />
              <col width="183px" />
              <col width="150px" />
              <col width="146px" />
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={list.filter((item) => item.checked).length === list.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <ThText>이메일</ThText>
                </Th>
                <Th>
                  <ThText>이름</ThText>
                </Th>
                <Th>
                  <ThText>가입일</ThText>
                </Th>
                <Th>
                  <ThText>계정 상태</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "94px" }}>계정 삭제</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "21px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setList([...list]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "181px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.email}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "97px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.name}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                    </Td>
                    <Td>
                      <TableFilterManager
                        onClick={() => {
                          filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.isActive}
                        notApprovedText={"비활성"}
                        list={[
                          {
                            value: true,
                            title: "활성",
                            onClick: () => {
                              setActiveId(item.id);
                              setActiveModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "비활성",
                            onClick: () => {
                              setActiveId(item.id);
                              setDeactiveModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "95px",
                        }}
                      >
                        <TableDelBtn
                          onClick={() => {
                            setDelId(item.id);
                            setDelModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "17px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.member_child_detail + `/${item.id}/1`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.member_child}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
      {deactiveModalOpen ? (
        <Modal
          title={modalMsg.deactivate?.title}
          setModalOpen={setDeactiveModalOpen}
          onClick={() => {
            activeFunc(false);
          }}
        />
      ) : (
        ""
      )}
      {activeModalOpen ? (
        <Modal
          title={modalMsg.activate?.title}
          setModalOpen={setActiveModalOpen}
          onClick={() => {
            activeFunc(true);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Child;
