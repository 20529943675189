import React, { useEffect, useRef } from "react";
import styled from "styled-components";

function TextArea({ type, placeholder, value, onChange, disabled, style }) {
  return (
    <>
      <TextAreaWrapper
        rows={1}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        style={{
          ...style,
        }}
      />
    </>
  );
}

export default TextArea;

const TextAreaWrapper = styled.textarea.attrs((props) => {})`
  width: 744px;
  height: 174px;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.25px;
  color: #262c31;
  resize: none;

  &::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.25px;
    color: #808991;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--sub-color-3, #27beff);
  }

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b0b8c1; /* 스크롤바의 색상 */
    border-radius: 9px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;
