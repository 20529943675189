import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { nullCheck } from "../../util/check";
import { regExpression } from "../../util/reg-expresstion";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import ManagerApi from "../../api/managerApi";

function AdminEdit(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [pwEdit, setPwEdit] = useState(false);
  const [PwErr, setPwErr] = useState(false);
  const [PwCheckErr, setPwCheckErr] = useState(false);

  const [saveData, setSaveData] = useState({
    name: "",
    email: "",
    managerAuthority: "",
    password: "",
    passwordCheck: "",
    passwordChangeFlag: false,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await ManagerApi.GetById(id);
      const data = response.data.data;
      setSaveData({
        ...data,
        password: "",
        passwordCheck: "",
        passwordChangeFlag: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  //비밀번호 체크
  useEffect(() => {
    if (saveData.password !== "" && !regExpression.password.test(saveData.password)) {
      setPwErr(true);
    } else setPwErr(false);
  }, [saveData.password]);

  //비밀번호 확인 체크
  useEffect(() => {
    if (saveData.passwordCheck !== "" && saveData.password !== saveData.passwordCheck) {
      setPwCheckErr(true);
    } else setPwCheckErr(false);
  }, [saveData.passwordCheck]);

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      const requestDto = {
        email: saveData.email,
        name: saveData.name,
        managerAuthority: saveData.managerAuthority,
        accessMenu: saveData.accessMenu,
        passwordChangeFlag: saveData.passwordChangeFlag,
        password: saveData.password,
      };

      await ManagerApi.Update(id, requestDto);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      if (error.response.status === 409) {
        toast(`이미 등록된 이메일 입니다.`);
      }
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>관리자 상세</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>이름</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={saveData.name}
                  onChange={(e) => setSaveData({ ...saveData, name: e.target.value })}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{saveData.email}</DetailFormText>
              </DetailPageForm>
              {pwEdit ? (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호*</DetailFormTitle>
                    <MainInput
                      type={"password"}
                      placeholder={"새로운 비밀번호를 입력해 주세요."}
                      helperText={"영문/숫자/특수문자 중 2가지 이상 포함, 8자 ~ 16자"}
                      onError={PwErr}
                      onChange={(e) => {
                        setSaveData({ ...saveData, password: e.target.value });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호 재입력*</DetailFormTitle>
                    <MainInput
                      type={"password"}
                      placeholder={"새로운 비밀번호를 한 번 더 입력해 주세요."}
                      helperText={"비밀번호가 일치하지 않습니다"}
                      onError={PwCheckErr}
                      onChange={(e) => {
                        setSaveData({ ...saveData, passwordCheck: e.target.value });
                      }}
                    />
                  </DetailPageForm>
                </>
              ) : (
                <DetailPageForm>
                  <DetailFormTitle>비밀번호</DetailFormTitle>
                  <PwEditBtn
                    onClick={() => {
                      setPwEdit(true);
                      saveData.passwordChangeFlag = true;
                      setSaveData({ ...saveData });
                    }}
                  >
                    비밀번호 수정하기
                  </PwEditBtn>
                </DetailPageForm>
              )}
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (nullCheck(saveData.name)) {
                  setSaveModalOpen(false);
                  return toast("이름을 입력해주세요.");
                }
                if (pwEdit && nullCheck(saveData.password)) {
                  setSaveModalOpen(false);
                  return toast("비밀번호를 입력해주세요");
                }
                if (pwEdit && nullCheck(saveData.passwordCheck)) {
                  setSaveModalOpen(false);
                  return toast("비밀번호를 한번 더 입력해주세요");
                }
                if (PwErr) {
                  setSaveModalOpen(false);
                  return toast("비밀번호를 올바르게 입력해주세요");
                }
                if (PwCheckErr) {
                  setSaveModalOpen(false);
                  return toast("비밀번호가 일치하지 않습니다");
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AdminEdit;

const PwEditBtn = styled.button.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #b8bfc4;
  background-color: transparent;
  color: #808991;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;
