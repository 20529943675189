import api from "./api.js";

export default class LeaveApi {
  static async GetList(params) {
    return await api.get(`admin/leaveMembers`, params);
  }
  static async DeleteById(leaveId) {
    return await api.del(`admin/leaveMember/${leaveId}`);
  }
}
