import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { route } from "../../router/route";
import DelBtn from "../../component/atoms/button/delBtn";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import moment from "moment";
import FileImgPreview from "../../component/molecules/fileImgPreview";
import Table from "../../component/atoms/layout/table/table";
import Tr from "../../component/atoms/layout/table/tr";
import Th from "../../component/atoms/layout/table/th";
import ThText from "../../component/atoms/text/table/thText";
import Td from "../../component/atoms/layout/table/td";
import TdText from "../../component/atoms/text/table/tdText";
import DetailBtn from "../../component/atoms/button/detailBtn";
import Pagenation from "../../component/molecules/pagenation";
import AcademyApi from "../../api/academyApi";

function AcademyDetail(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const { page } = useParams();

  const [data, setData] = useState({});

  const [delModalOpen, setDelModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getMemberList();
  }, [page]);

  const getData = async () => {
    try {
      const response = (await AcademyApi.GetById(id)).data.data;
      setData(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);

  const getMemberList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };
      const list = (await AcademyApi.GetMember(id, data)).data.data;

      setList(list.content);
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      await AcademyApi.DeleteById(id);

      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.academy_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "268px", marginBottom: "20px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>학원명</DetailFormTitle>
                <DetailFormText>{data.academyName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>학원 등록 유저</DetailFormTitle>
                <DetailFormText>{data.academyName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주소</DetailFormTitle>
                <DetailFormText>
                  {data.address}
                  <br />
                  {data.addressDetail}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
          <DetailPage style={{ minHeight: "794px" }}>
            <DetailPageTitle>등록계정 {pagination.totalCount}</DetailPageTitle>
            <Table
              style={{
                width: "964px",
              }}
            >
              <colgroup>
                <col width="241px" />
                <col width="156px" />
                <col width="164px" />
                <col width="258px" />
                <col width="110px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <ThText>이메일</ThText>
                  </Th>
                  <Th>
                    <ThText>이름</ThText>
                  </Th>
                  <Th>
                    <ThText>가입일</ThText>
                  </Th>
                  <Th>
                    <ThText>학교 정보</ThText>
                  </Th>
                  <Th>
                    <ThText style={{ textAlign: "center", paddingRight: "48px" }}>더보기</ThText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {list.map((item, index) => {
                  return (
                    <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                      <Td>
                        <TdText
                          style={{
                            paddingLeft: "20px",
                            width: "291px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.email}
                        </TdText>
                      </Td>
                      <Td>
                        <TdText
                          style={{
                            width: "207px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.name}
                        </TdText>
                      </Td>
                      <Td>
                        <TdText>{moment(item.createdAt).format("YYYY/MM/DD")}</TdText>
                      </Td>
                      <Td>
                        <TdText
                          style={{
                            width: "200px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {`${item.schoolName} ${item.grade}학년 ${item.schoolClass}반 ${item.classNumber}번`}
                        </TdText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingRight: "47px",
                          }}
                        >
                          <DetailBtn
                            onClick={() => navigate(`${route.member_child_detail}/${item.id}/1`)}
                          />
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagenation
              style={{ width: "964px" }}
              route={route.academy_detail + `/${id}`}
              params={`${id}`}
              queryString={``}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AcademyDetail;
