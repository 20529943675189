import api from "./api.js";

export default class TermsApi {
  static async Get(data) {
    return await api.get(`common/terms`, data);
  }

  static async GetById(termsId) {
    return await api.get(`common/terms/${termsId}`);
  }

  static async Add(data) {
    return await api.post(`admin/terms`, data);
  }

  static async Update(termsId, data) {
    return await api.put(`admin/terms/${termsId}`, data);
  }

  static async Delete(termsId) {
    return await api.del(`admin/terms/${termsId}`);
  }

  static async DeleteChecked(data) {
    return await api.del(`admin/managers`, "", data);
  }
}
