import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/error-handler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import { nullCheck } from "../../../util/check";
import BaseApi from "../../../api/baseApi";
import { modalMsg } from "../../../util/modalMsg";

function BasicEdit(props) {
  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [representative, setRepresentative] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await BaseApi.Get()).data.data;

      setBusinessName(response.businessName);
      setRepresentative(response.representative);
      setBusinessNumber(response.businessNumber);
      setPhoneNumber(response.phoneNumber);
      setAddress(response.address);
      setEmail(response.email);
    } catch (error) {
      errorHandler(error);
    }
  };

  const saveFunc = async () => {
    try {
      const requestDto = {
        email: email,
        businessName: businessName,
        representative: representative,
        businessNumber: businessNumber,
        phoneNumber: phoneNumber,
        address: address,
      };

      await BaseApi.Update(requestDto);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <DetailTop>
            <TableTitle>기본 관리</TableTitle>
          </DetailTop>
          <DetailPage style={{ minHeight: "602px" }}>
            <DetailPageTitle>기본 관리</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>상호명*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"상호명을 입력해주세요"}
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>대표자*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"대표자를 입력해주세요"}
                  value={representative}
                  onChange={(e) => setRepresentative(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>사업자등록번호*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"사업자등록번호를 입력해주세요"}
                  value={businessNumber}
                  onChange={(e) => setBusinessNumber(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주소*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"주소를 입력해주세요"}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>전화번호*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"전화번호를 입력해주세요"}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"이메일를 입력해주세요"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (
                  nullCheck(businessName) ||
                  nullCheck(representative) ||
                  nullCheck(businessNumber) ||
                  nullCheck(address) ||
                  nullCheck(phoneNumber) ||
                  nullCheck(email)
                ) {
                  setSaveModalOpen(false);
                  return toast("모든 정보를 입력해주세요.");
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </TableLayout>

        {saveModalOpen ? (
          <Modal
            title={modalMsg.update?.title}
            text={modalMsg.update?.content}
            setModalOpen={setSaveModalOpen}
            onClick={() => {
              saveFunc();
            }}
          />
        ) : null}
      </MainLayout>
    </>
  );
}

export default BasicEdit;
