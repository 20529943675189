import api from "./api.js";

export default class BannerApi {
  static async GetById(id) {
    return await api.get(`admin/banners/${id}`);
  }

  static async Add(id) {
    return await api.post(`admin/banner/${id}`);
  }

  static async Edit(id, data) {
    return await api.put(`admin/banner/${id}`, data);
  }

  static async Del(id) {
    return await api.del(`admin/banner/${id}`);
  }
}
