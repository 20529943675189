import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useRecoilState } from "recoil";
import { memberFilterState } from "../../recoil/memberFilter";

function TableDropDown({ setSearchParams, filterList }) {
  const [filterName, setFilterName] = useRecoilState(memberFilterState);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <TableFilter onClick={() => setIsOpen(!isOpen)}>
        <FilterText style={{ color: filterName?.title ? "#262C31" : "" }}>
          {filterName?.title ? filterName.title : "필터"}
        </FilterText>
        <img src="/assets/admin/icons/dropdown_down.svg" />
      </TableFilter>
      {isOpen ? (
        <DropDownWrapper>
          {filterList?.map((item, index) => {
            return (
              <DropDownItem
                key={index}
                onClick={() => {
                  setFilterName(item);
                  setSearchParams("");
                  setIsOpen(false);
                }}
              >
                {item.title}
              </DropDownItem>
            );
          })}
        </DropDownWrapper>
      ) : null}
    </div>
  );
}

export default TableDropDown;

const TableFilter = styled.div.attrs((props) => {})`
  height: 42px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 20px;
  background: #f2f4f5;
  cursor: pointer;
`;

const FilterText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  color: #b8bfc4;
`;

const DropDownWrapper = styled.div.attrs((props) => {})`
  width: 115px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background: #f2f4f5;
  cursor: pointer;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 5;
`;

const DropDownItem = styled.div.attrs((props) => {})`
  width: 95px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #808991;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.5px;
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
