import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

const TdText = (props) => {
  return <TdTextWrapper {...props} onClick={props.onClick} />;
};

export default TdText;

const TdTextWrapper = styled(PretendardText).attrs((props) => {})`
  color: #636c73;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.042px;
`;
