import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";

function Pagenation({
  route,
  totalPages,
  currentPage,
  queryString,
  rangeSize,
  first,
  last,
  style,
}) {
  const navigate = useNavigate();

  const pageList = useMemo(() => {
    const pageGroup = Math.ceil(currentPage / rangeSize);

    let firstNumber = pageGroup * rangeSize - (rangeSize - 1);
    if (firstNumber <= 0) {
      firstNumber = 1;
    }

    let lastNumber = pageGroup * rangeSize;
    if (lastNumber > totalPages) {
      lastNumber = totalPages;
    }

    const pageList = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      pageList.push(i);
    }
    return pageList;
  }, [totalPages, currentPage, rangeSize]);

  return (
    <>
      {pageList.length > 0 ? (
        <>
          <PagenationWrapper style={style}>
            <div
              style={{
                cursor: "pointer",
                height: "28px",
              }}
              onClick={() => {
                if (first) {
                  return;
                }

                navigate(route + `/${Number(currentPage) - 1}?${queryString}`);
              }}
            >
              <img src="/assets/admin/icons/ico_arrow_left.svg" alt="arrow-left" />
            </div>
            <PagenationNumWrapper>
              {pageList.map((item, index) => {
                return (
                  <PagenationNum
                    key={index}
                    style={{
                      backgroundColor: Number(currentPage) === item ? "#F2F4F5" : "#fff",
                      color: Number(currentPage) === item ? "#262C31" : "#B8BFC4",
                    }}
                    onClick={() => {
                      navigate(route + `/${item}?${queryString}`);
                    }}
                  >
                    {item}
                  </PagenationNum>
                );
              })}
            </PagenationNumWrapper>
            <div
              style={{
                cursor: "pointer",
                height: "28px",
              }}
              onClick={() => {
                if (last) {
                  return;
                }

                navigate(route + `/${Number(currentPage) + 1}?${queryString}`);
              }}
            >
              <img
                src="/assets/admin/icons/ico_arrow_left.svg"
                alt="arrow-left"
                style={{ transform: "rotate(180deg)" }}
              />
            </div>
          </PagenationWrapper>
        </>
      ) : null}
    </>
  );
}

export default Pagenation;

const PagenationWrapper = styled.div.attrs((props) => {})`
  margin-top: 40px;
  width: 1044px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const PagenationNum = styled(PretendardText).attrs((props) => {})`
  display: flex;
  font-weight: 700;
  line-height: 28px;

  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #b8bfc4;
  border-radius: 6px;
`;

const PagenationNumWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
`;
