import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";

function TableCount({ text, count }) {
  return (
    <>
      <TableCountWrapper>
        <PretendardText
          style={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.75px", color: "#ADB4BA" }}
        >
          전체 {text} 수
        </PretendardText>
        <PretendardText
          style={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.75px", color: "#262C31" }}
        >
          {count || "000"}
        </PretendardText>
      </TableCountWrapper>
    </>
  );
}

export default TableCount;

const TableCountWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 7px;
`;
