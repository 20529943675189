import api from "./api.js";

export default class PopupsApi {
  static async Get(data) {
    return await api.get(`admin/popups`, data);
  }

  static async GetById(popupId) {
    return await api.get(`admin/popup/${popupId}`);
  }

  static async Add(data) {
    return await api.post(`admin/popup`, data);
  }

  static async Update(popupId, data) {
    return await api.put(`admin/popup/${popupId}`, data);
  }

  static async Delete(popupId) {
    return await api.del(`admin/popup/${popupId}`);
  }

  static async DeleteChecked(data) {
    return await api.del(`admin/popups`, "", data);
  }

  static async Activate(popupId, data) {
    return await api.put(`admin/popup/activation/${popupId}`, data);
  }
}
