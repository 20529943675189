import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import { errorHandler } from "../../../util/error-handler";
import BannerApi from "../../../api/bannerApi";
import MainInput from "../../../component/atoms/input/mainInput";
import ImgInput from "../../../component/atoms/input/imgInput";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import AddBtn from "../../../component/atoms/button/addBtn";
import { fileUpload } from "../../../util/file-management";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { nullCheck } from "../../../util/check";

function BannerDetail(props) {
  const { id } = useParams();
  const location = useLocation();
  const [title, setTitle] = useState("");

  const [bannerId, setBannerId] = useState(-1);

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [delModalOpen, setDelModalOpen] = useState(false);

  const [changeFile, setChangeFile] = useState("");
  const [changeFileUrl, setChangeFileUrl] = useState("");
  const [changeLink, setChangeLink] = useState("");
  const [changeId, setChangeId] = useState(-1);
  const [changeIndex, setChangeIndex] = useState(-1);

  const [data, setData] = useState();

  useEffect(() => {
    setTitle(location.state?.title);
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await BannerApi.GetById(id)).data.data;

      setData(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (data) {
      changeBanner(changeId, changeIndex);
    }
  }, [changeFile, changeFileUrl, changeLink]);

  const changeBanner = (id, index) => {
    const copyArr = [...data];
    const filterData = data.filter((item) => item.id === id);

    if (changeFileUrl) filterData[0].fileUrl = changeFileUrl;
    if (changeFile) filterData[0].file = changeFile;
    if (changeLink || changeLink.length >= 0) filterData[0].link = changeLink;

    copyArr[index] = filterData[0];

    setData(copyArr);
  };

  const delbannerImg = (id, index) => {
    const copyArr = [...data];
    const filterData = data.filter((item) => item.id === id);

    filterData[0].fileUrl = "";
    filterData[0].file = "";

    copyArr[index] = filterData[0];

    setData(copyArr);
  };

  const deleteBanner = async () => {
    try {
      await BannerApi.Del(bannerId);
      getData();
      setDelModalOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const addBanner = async () => {
    try {
      await BannerApi.Add(id);
      getData();
    } catch (error) {
      errorHandler(error);
    }
  };

  const editBanner = async () => {
    try {
      const formData = new FormData();
      formData.append("link", data[changeIndex].link);
      if (data[changeIndex].file) {
        formData.append("file", data[changeIndex].file);
      }

      await BannerApi.Edit(bannerId, formData);
      getData();
      setSaveModalOpen(false);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <div>
            {data?.map((item, index) => {
              return (
                <DetailPage key={index} style={{ minHeight: "365px", marginBottom: "20px" }}>
                  <DetailPageTitle>{title}</DetailPageTitle>
                  <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <DetailPageForm style={{ alignItems: "flex-start" }}>
                      <DetailFormTitle>
                        이미지*
                        <br />
                        <span style={{ fontWeight: 400 }}>(권장사이즈 686*198px)</span>
                      </DetailFormTitle>
                      {item.fileUrl ? (
                        <div style={{ position: "relative", width: "90px", height: "90px" }}>
                          <img
                            style={{ width: "90px", height: "90px", borderRadius: "3px" }}
                            src={item.fileUrl}
                          />
                          <img
                            src="/assets/admin/icons/del_x.svg"
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() => delbannerImg(item.id, index)}
                          />
                        </div>
                      ) : (
                        <ImgInput
                          id={item.id}
                          onChange={(e) => {
                            fileUpload(e, setChangeFileUrl, setChangeFile);
                            setChangeId(item.id);
                            setChangeIndex(index);
                          }}
                        />
                      )}
                    </DetailPageForm>
                    <DetailPageForm>
                      <DetailFormTitle>연결링크</DetailFormTitle>
                      <MainInput
                        placeholder={"https://"}
                        value={item.link}
                        onChange={(e) => {
                          setChangeLink(e.target.value);
                          setChangeId(item.id);
                          setChangeIndex(index);
                        }}
                      ></MainInput>
                    </DetailPageForm>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      position: "absolute",
                      right: 40,
                      bottom: 40,
                    }}
                  >
                    <DelBtn
                      onClick={() => {
                        setBannerId(item.id);
                        setDelModalOpen(true);
                      }}
                    />
                    <EditBtn
                      text={"저장하기"}
                      onClick={() => {
                        if (nullCheck(item.fileUrl)) {
                          setSaveModalOpen(false);
                          return toast("모든 내용을 입력해주세요.");
                        }
                        setBannerId(item.id);
                        setChangeIndex(index);
                        setSaveModalOpen(true);
                      }}
                    />
                  </div>
                </DetailPage>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              marginBottom: "100px",
            }}
          >
            <AddBtn onClick={addBanner} />
          </div>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteBanner();
          }}
        />
      ) : (
        ""
      )}
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={editBanner}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default BannerDetail;
