import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import EditBtn from "../../../component/atoms/button/editBtn";
import { errorHandler } from "../../../util/error-handler";
import { route } from "../../../router/route";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import BaseApi from "../../../api/baseApi";

function Basic(props) {
  const navigate = useNavigate();

  const [saveData, setSaveData] = useState({
    businessName: "",
    representative: "",
    businessNumber: "",
    phoneNumber: "",
    address: "",
    email: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi.Get();
      setSaveData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <DetailTop>
            <TableTitle>기본 관리</TableTitle>
            <EditBtn onClick={() => navigate(route.service_basic_edit)} />
          </DetailTop>
          <DetailPage style={{ minHeight: "380px" }}>
            <DetailPageTitle>사업자 정보</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>상호명</DetailFormTitle>
                <DetailFormText>{saveData.businessName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>대표자</DetailFormTitle>
                <DetailFormText>{saveData.representative}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>사업자등록번호</DetailFormTitle>
                <DetailFormText>{saveData.businessNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주소</DetailFormTitle>
                <DetailFormText>{saveData.address}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <DetailFormText>{saveData.phoneNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{saveData.email}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </TableLayout>
      </MainLayout>
    </>
  );
}

export default Basic;
