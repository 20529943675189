import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import TableLayout from "../../component/atoms/layout/table/tableLayout";
import TableTitle from "../../component/atoms/text/table/tableTitle";
import TableTop from "../../component/atoms/layout/table/tableTop";
import TableSearchBtn from "../../component/atoms/layout/table/tableSearchBtn";
import TableCount from "../../component/molecules/tableCount";
import SearchBar from "../../component/atoms/input/searchBar";
import Table from "../../component/atoms/layout/table/table";
import Tr from "../../component/atoms/layout/table/tr";
import Checkbox from "../../component/atoms/input/checkbox";
import Th from "../../component/atoms/layout/table/th";
import Td from "../../component/atoms/layout/table/td";
import Pagenation from "../../component/molecules/pagenation";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import moment from "moment";
import Modal from "../../component/templates/Modal";
import { errorHandler } from "../../util/error-handler";
import { route } from "../../router/route";
import ThText from "../../component/atoms/text/table/thText";
import TdText from "../../component/atoms/text/table/tdText";
import DelBtn from "../../component/atoms/button/delBtn";
import DetailBtn from "../../component/atoms/button/detailBtn";
import { modalMsg } from "../../util/modalMsg";
import AddBtn from "../../component/atoms/button/addBtn";
import NoticeApi from "../../api/noticeApi";
import ExcelBtn from "../../component/atoms/button/excelBtn";
import AcademyApi from "../../api/academyApi";
import ExcelApi from "../../api/excelApi";
import { excelDownload } from "../../util/file-management";
import PretendardText from "../../component/atoms/text/pretendardText";

function Academy(props) {
  const { page } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [delIdList, setDelIdList] = useState([]);
  const [delListModalOpen, setDelListModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: page - 1,
        size: pagination.size,
      };
      let list = (await AcademyApi.Get(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteFunc = async () => {
    try {
      const formData = new FormData();
      for (let index = 0; index < delIdList.length; index++) {
        const element = delIdList[index];
        formData.append("idList", element.id);
      }

      await NoticeApi.Delete(formData);
      toast(toastMsg.delete);
      setDelListModalOpen(false);
      setDelIdList([]);
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  async function getExcel(isAll) {
    if (isAll) {
      await getExcelAll();
      setLoading(false);
    } else {
      await getExcelChecked();
      setLoading(false);
    }
  }

  const getExcelChecked = async () => {
    try {
      const filter = list
        .filter((item) => item.checked)
        .map((item02) => {
          return item02.id;
        });

      if (filter.length === 0) {
        toast("항목을 선택해주세요");
        return;
      }

      setLoading(true);

      const data = {
        idList: filter,
        isAll: false,
      };

      const response = await ExcelApi.getAcademyExcel(data);
      excelDownload(response, "학원 관리.xlsx");
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelAll = async () => {
    setLoading(true);
    try {
      const data = {
        idList: [0],
        isAll: true,
      };

      const response = await ExcelApi.getAcademyExcel(data);
      excelDownload(response, "학원 관리.xlsx");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-wrapper">
          <div className="loading-spinner-wrapper">
            <div className="loading-spinner">
              <img src="/assets/admin/icons/loading-spinner.svg" alt="" />
              <PretendardText style={{ fontSize: "14px", fontWeight: "700", color: "#fff" }}>
                잠시만 기다려주세요
              </PretendardText>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>학원 관리</TableTitle>
          <TableTop>
            <TableCount text={"학원"} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                placeholder={"학원명, 주소를 입력해주세요"}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.academy}/1?keyword=${keyword}`, { replace: true });
                  }
                }}
                onClick={() => {
                  navigate(`${route.academy}/1?keyword=${keyword}`, { replace: true });
                }}
              />
              <ExcelBtn onSelectClick={() => getExcel(false)} onAllClick={() => getExcel(true)} />
              <AddBtn onClick={() => navigate(route.academy_add)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="241px" />
              <col width="339px" />
              <col width="296px" />
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={list.filter((item) => item.checked).length === list.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setList(
                          list.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <ThText>학원명</ThText>
                </Th>
                <Th>
                  <ThText>주소</ThText>
                </Th>
                <Th>
                  <ThText>학원 등록 유저</ThText>
                </Th>
                <Th>
                  <ThText style={{ textAlign: "center", paddingRight: "17px" }}>상세보기</ThText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <Tr style={{ height: "57px", background: "#fff" }} key={index}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setList([...list]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "181px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.academyName}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText
                        style={{
                          width: "314px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          wordBreak: "break-all",
                        }}
                      >
                        {item.address} {item.addressDetail}
                      </TdText>
                    </Td>
                    <Td>
                      <TdText>
                        {item.postUserEmail}
                        {item.isAdmin ? "(관리자)" : ""}
                      </TdText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "17px",
                        }}
                      >
                        <DetailBtn
                          onClick={() => {
                            navigate(route.academy_detail + `/${item.id}/1`);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagenation
            route={route.academy}
            queryString={`keyword=${keyword}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {delListModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDelListModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Academy;
