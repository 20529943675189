import React, { useState } from "react";
import styled from "styled-components";
import PretendardText from "../text/pretendardText";

function ExcelBtn({ onSelectClick, onAllClick, style }) {
  const [isOpen, setIsOpen] = useState();
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <ExcelBtnWrapper
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        style={{ ...style, cursor: "pointer" }}
      >
        <img src="/assets/admin/icons/ico_excel_download.svg" alt="" />
        <PretendardText
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "22px",
            color: "#FFF",
          }}
        >
          엑셀로 내보내기
        </PretendardText>
        {/* <img src="/assets/admin/icons/ico_excel_arrow.svg" alt="" /> */}
      </ExcelBtnWrapper>
      {isOpen ? (
        <SubmenuWrapper>
          <SubMenuText
            onClick={() => {
              onSelectClick();
              setIsOpen(false);
            }}
          >
            선택 항목 내려받기
          </SubMenuText>
          <SubMenuText
            onClick={() => {
              onAllClick();
              setIsOpen(false);
            }}
          >
            전체 항목 내려받기
          </SubMenuText>
        </SubmenuWrapper>
      ) : (
        ""
      )}
    </div>
  );
}

export default ExcelBtn;

const ExcelBtnWrapper = styled.div.attrs((props) => {})`
  padding: 10px 14px;
  width: 152px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 23px;
  background: #333;
  cursor: pointer;
`;

const SubmenuWrapper = styled.div.attrs((props) => {})`
  padding: 10px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 48px;
  z-index: 5;
`;

const SubMenuText = styled(PretendardText).attrs((props) => {})`
  padding: 10px;
  color: #262c31;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.5px;
  cursor: pointer;
`;
