import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

const DetailFormText = (props) => {
  return <DetailFormTextWrapper {...props} />;
};

export default DetailFormText;

const DetailFormTextWrapper = styled(PretendardText).attrs((props) => {})`
  font-size: 15px;
  font-weight: 400;
  line-height: 21.25px;
  color: #5a636a;
  max-width: 744px;
`;
