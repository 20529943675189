import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import TermsApi from "../../../api/termsApi";

function TermsEdit(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [data, setData] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await TermsApi.GetById(id);
      setData({
        ...response.data.data,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const saveFunc = async () => {
    try {
      const requestDto = {
        title: data.title,
        content: data.content,
      };

      await TermsApi.Update(id, requestDto);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>약관 수정</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>약관명*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"약관명을 입력해주세요"}
                  value={data.title}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>약관내용*</DetailFormTitle>
                <TextArea
                  style={{ minHeight: "133px" }}
                  placeholder={"약관내용을 입력해주세요"}
                  value={data.content}
                  onChange={(e) => setData({ ...data, content: e.target.value })}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (nullCheck(data.title) || nullCheck(data.content)) {
                  setSaveModalOpen(false);
                  return toast("모든 내용을 입력해주세요.");
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default TermsEdit;
