import axios from "axios";
const url = "https://orangeschool.kr";

export default class ExcelApi {
  static async getExcel(data) {
    return await axios.post(`${url}/api/admin/excel/commonMember`, data, {
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      responseType: "blob",
    });
  }

  static async getAcademyExcel(data) {
    return await axios.post(`${url}/api/admin/excel/academy`, data, {
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      responseType: "blob",
    });
  }
}
