import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import PostInput from "./../../../component/atoms/input/postInput";
import MemberApi from "../../../api/memberApi";
import Radio from "../../../component/atoms/input/radio";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import { fileUpload } from "../../../util/file-management";

function ParentEdit(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birth, setBirth] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [originFileName, setOriginFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState("");

  const [deleteFileFlag, setDeleteFileFlag] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await MemberApi.GetById(id)).data.data;

      setName(response.name);
      setGender(response.gender);
      setBirth(response.birth);
      setEmail(response.email);
      setPhoneNumber(response.phoneNumber);
      setAddress(response.address);
      setAddressDetail(response.addressDetail);
      setOriginFileName(response.originFileName);
      setFileUrl(response.fileUrl);
    } catch (error) {
      errorHandler(error);
    }
  };

  const saveFunc = async () => {
    try {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("gender", gender);
      formData.append("birth", birth);
      formData.append("deleteFileFlag", deleteFileFlag);
      formData.append("address", address);
      formData.append("addressDetail", addressDetail);
      if (file) {
        formData.append("file", file);
      }

      await MemberApi.Edit(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  function findAddrress() {
    let address = "";
    new window.daum.Postcode({
      oncomplete: function (data) {
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          address = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          address = data.jibunAddress;
        }
        setAddress(address);
      },
    }).open();
  }

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "813px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>이름</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>성별</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"MAN"}
                    valueText={"남성"}
                    name={"type"}
                    checked={gender === 1}
                    onChange={() => setGender(1)}
                  />
                  <Radio
                    id={"WOMAN"}
                    valueText={"여성"}
                    name={"type"}
                    checked={gender === 2}
                    onChange={() => setGender(2)}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>생년월일</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"ex) 97/06/08"}
                  value={birth}
                  onChange={(e) => setBirth(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>휴대폰 번호</DetailFormTitle>
                <MainInput type={"text"} value={phoneNumber} disabled={true} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <MainInput type={"text"} value={email} disabled={true} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>주소</DetailFormTitle>
                <div>
                  <PostInput
                    placeholder={"기본 주소"}
                    onClick={findAddrress}
                    address={address}
                    style={{ marginBottom: "8px" }}
                  />
                  <MainInput
                    type={"text"}
                    placeholder={"상세 주소"}
                    value={addressDetail}
                    onChange={(e) => setAddressDetail(e.target.value)}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>프로필 이미지</DetailFormTitle>
                {fileUrl ? (
                  <ImgPreview
                    fileUrl={fileUrl}
                    onClick={() => {
                      setFileUrl("");
                      setFile("");
                      if (originFileName) {
                        setDeleteFileFlag(true);
                      }
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"childImg"}
                    accept={"image/*"}
                    onChange={(e) => {
                      fileUpload(e, setFileUrl, setFile);
                    }}
                  />
                )}
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (
                  nullCheck(name) ||
                  nullCheck(address) ||
                  nullCheck(birth) ||
                  nullCheck(address) ||
                  nullCheck(addressDetail)
                ) {
                  toast(toastMsg.input_empty);
                  return;
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ParentEdit;
