import React, { useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "../../../component/atoms/input/textarea";
import Radio from "../../../component/atoms/input/radio";
import AlarmApi from "../../../api/alarmApi";
// import ManagerApi from "../../../api/manager-api";

function AlarmAdd(props) {
  const navigate = useNavigate();

  const [alarmType, setAlarmType] = useState("");
  const [alarmMemberType, setAlarmMemberType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [data, setData] = useState({
    alarmType: "",
    title: "",
    content: "",
  });

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      const requestDto = {
        alarmType: alarmType,
        alarmMemberType: alarmMemberType,
        title: title,
        content: content,
      };

      await AlarmApi.Add(requestDto);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>추가하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>유형*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"SERVICE"}
                    valueText={"서비스알림"}
                    name={"author"}
                    checked={alarmType === "SERVICE"}
                    onChange={() => setAlarmType("SERVICE")}
                  />
                  <Radio
                    id={"AD"}
                    valueText={"광고성알림"}
                    name={"author"}
                    checked={alarmType === "AD"}
                    onChange={() => setAlarmType("AD")}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>구분*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ALL"}
                    valueText={"전체"}
                    name={"type"}
                    checked={alarmMemberType === "ALL"}
                    onChange={() => setAlarmMemberType("ALL")}
                  />
                  <Radio
                    id={"PARENT"}
                    valueText={"보호자"}
                    name={"type"}
                    checked={alarmMemberType === "PARENT"}
                    onChange={() => setAlarmMemberType("PARENT")}
                  />
                  <Radio
                    id={"CHILD"}
                    valueText={"자녀"}
                    name={"type"}
                    checked={alarmMemberType === "CHILD"}
                    onChange={() => setAlarmMemberType("CHILD")}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>제목*</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"제목을 입력해주세요"}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>내용*</DetailFormTitle>
                <TextArea
                  placeholder={"내용을 입력해주세요"}
                  onChange={(e) => setContent(e.target.value)}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"등록하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (
                  nullCheck(alarmType) ||
                  nullCheck(alarmMemberType) ||
                  nullCheck(title) ||
                  nullCheck(content)
                ) {
                  setSaveModalOpen(false);
                  return toast("모든 내용을 입력해주세요.");
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.create?.title}
          // text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AlarmAdd;
