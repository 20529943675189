import { Routes, Route } from "react-router-dom";
import React from "react";
import { route } from "./route";
import Login from "../pages/login/login";
import Join from "../pages/join/join";
import JoinComplete from "../pages/join/joinComplete";
import Home from "../pages/home/home";
import Manager from "../pages/manager/manager";
import ManagerDetail from "./../pages/manager/managerDetail";
import RootEdit from "./../pages/manager/rootEdit";
import AdminEdit from "./../pages/manager/adminEdit";
import Basic from "./../pages/service/basic/basic";
import BasicEdit from "./../pages/service/basic/basicEdit";
import Terms from "./../pages/service/terms/terms";
import TermsDetail from "./../pages/service/terms/termsDetail";
import TermsEdit from "./../pages/service/terms/termsEdit";
import TermsAdd from "./../pages/service/terms/termsAdd";
import Popup from "./../pages/service/popup/popup";
import PopupDetail from "./../pages/service/popup/popupDetail";
import PopupEdit from "./../pages/service/popup/popupEdit";
import PopupAdd from "./../pages/service/popup/popupAdd";
import Alarm from "./../pages/service/alarm/alarm";
import AlarmDetail from "./../pages/service/alarm/alarmDetail";
import AlarmAdd from "./../pages/service/alarm/alarmAdd";
import Banner from "../pages/service/banner/banner";
import BannerDetail from "../pages/service/banner/bannerDetail";
import Notice from "./../pages/service/notice/notice";
import NoticeDetail from "./../pages/service/notice/noticeDetail";
import NoticeAdd from "./../pages/service/notice/noticeAdd";
import Leave from "../pages/member/leave/leave";
import Academy from "../pages/academy/academy";
import AcademyDetail from "../pages/academy/academyDetail";
import AcademyEdit from "../pages/academy/academyEdit";
import AcademyAdd from "../pages/academy/academyAdd";
import OsPick from "../pages/osPick/osPick";
import OsPickDetail from "../pages/osPick/osPickDetail";
import OsPickEdit from "../pages/osPick/osPickEdit";
import OsPickAdd from "../pages/osPick/osPickAdd";
import Child from "../pages/member/child/child";
import ChildDetail from "../pages/member/child/childDetail";
import ChildEdit from "../pages/member/child/childEdit";
import Parent from "../pages/member/parent/parent";
import ParentDetail from "../pages/member/parent/parentDetail";
import ParentEdit from "../pages/member/parent/parentEdit";

const Router = () => {
  return (
    <Routes>
      {/* default */}
      <Route path={route.default} element={<Login />} />

      {/* 로그인 */}
      <Route path={route.login} element={<Login />} />
      <Route path={route.join} element={<Join />} />
      <Route path={route.join_complete} element={<JoinComplete />} />

      {/* 홈 */}
      <Route path={route.home} element={<Home />} />

      {/* 회원 관리 */}
      <Route path={route.member_parents + "/:page"} element={<Parent />} />
      <Route path={route.member_parents_detail + "/:id/:page"} element={<ParentDetail />} />
      <Route path={route.member_parents_edit + "/:id"} element={<ParentEdit />} />
      <Route path={route.member_child + "/:page"} element={<Child />} />
      <Route path={route.member_child_detail + "/:id/:page"} element={<ChildDetail />} />
      <Route path={route.member_child_edit + "/:id"} element={<ChildEdit />} />
      <Route path={route.member_leave + "/:page"} element={<Leave />} />

      {/* os pick */}
      <Route path={route.os_pick + "/:page"} element={<OsPick />} />
      <Route path={route.os_pick_detail + "/:id"} element={<OsPickDetail />} />
      <Route path={route.os_pick_edit + "/:id"} element={<OsPickEdit />} />
      <Route path={route.os_pick_add} element={<OsPickAdd />} />

      {/* 학원 관리 */}
      <Route path={route.academy + "/:page"} element={<Academy />} />
      <Route path={route.academy_detail + "/:id/:page"} element={<AcademyDetail />} />
      <Route path={route.academy_edit + "/:id"} element={<AcademyEdit />} />
      <Route path={route.academy_add} element={<AcademyAdd />} />

      {/* 서비스 관리 */}
      <Route path={route.service_basic + "/:page"} element={<Basic />} />
      <Route path={route.service_basic_edit} element={<BasicEdit />} />
      <Route path={route.service_banner + "/:page"} element={<Banner />} />
      <Route path={route.service_banner_detail + "/:id"} element={<BannerDetail />} />
      <Route path={route.service_terms + "/:page"} element={<Terms />} />
      <Route path={route.service_terms_detail + "/:id"} element={<TermsDetail />} />
      <Route path={route.service_terms_edit + "/:id"} element={<TermsEdit />} />
      <Route path={route.service_terms_add + "/:id"} element={<TermsAdd />} />
      <Route path={route.service_popup + "/:page"} element={<Popup />} />
      <Route path={route.service_popup_detail + "/:id"} element={<PopupDetail />} />
      <Route path={route.service_popup_edit + "/:id"} element={<PopupEdit />} />
      <Route path={route.service_popup_add} element={<PopupAdd />} />
      <Route path={route.service_notice + "/:page"} element={<Notice />} />
      <Route path={route.service_notice_detail + "/:id"} element={<NoticeDetail />} />
      <Route path={route.service_notice_add} element={<NoticeAdd />} />
      <Route path={route.service_alarm + "/:page"} element={<Alarm />} />
      <Route path={route.service_alarm_detail + "/:id"} element={<AlarmDetail />} />
      <Route path={route.service_alarm_add} element={<AlarmAdd />} />

      {/* 관리자 권한 관리 */}
      <Route path={route.manager + "/:page"} element={<Manager />} />
      <Route path={route.manager_detail + "/:id"} element={<ManagerDetail />} />
      <Route path={route.manager_edit_root + "/:id"} element={<RootEdit />} />
      <Route path={route.manager_edit_admin + "/:id"} element={<AdminEdit />} />
    </Routes>
  );
};

export default Router;
