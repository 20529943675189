import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import PretendardText from "../atoms/text/pretendardText";
import { useSetRecoilState } from "recoil";
import { memberFilterState } from "../../recoil/memberFilter";
import { osTabState, tabState } from "../../recoil/tabState";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(0);

  const setFilterName = useSetRecoilState(memberFilterState);
  const setTab = useSetRecoilState(tabState);
  const setOsTab = useSetRecoilState(osTabState);

  //경로 저장
  const location = useLocation();
  const [path, setPath] = useState(null);
  const [menus, setMenus] = useState([
    {
      title: "홈",
      link: route.home,
      type: "noList",
      subMenus: [],
      isView: true,
    },
    {
      title: "사용자 관리",
      link: route.member,
      subMenus: [
        {
          title: "보호자회원 관리",
          link: route.member_parents,
          isView: true,
        },
        {
          title: "자녀회원 관리",
          link: route.member_child,
          isView: true,
        },
        {
          title: "탈퇴회원 관리",
          link: route.member_leave,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "O'S PICK 관리",
      link: route.os_pick,
      subMenus: [],
      isView: true,
    },
    {
      title: "학원 관리",
      link: route.academy,
      subMenus: [],
      isView: true,
    },
    {
      title: "서비스 관리",
      link: route.service,
      subMenus: [
        {
          title: "알림 관리",
          link: route.service_alarm,
          isView: true,
        },
        {
          title: "공지사항 관리",
          link: route.service_notice,
          isView: true,
        },
        {
          title: "팝업 관리",
          link: route.service_popup,
          isView: true,
        },
        {
          title: "배너 관리",
          link: route.service_banner,
          isView: true,
        },
        {
          title: "약관 관리",
          link: route.service_terms,
          isView: true,
        },
        {
          title: "기본 관리",
          link: route.service_basic,
          isView: true,
        },
      ],
      isView: true,
    },
    {
      title: "관리자회원",
      link: route.manager,
      subMenus: [],
      isView: true,
    },
  ]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  // useEffect(() => {
  //   setPath(location.pathname);
  //   if (sessionStorage.getItem("managerAuthority") !== "ROOT") {
  //     const accessMenu = sessionStorage.getItem("accessMenu");
  //     for (let index = 0; index < menus.length; index++) {
  //       const element = menus[index];
  //       if (element.subMenus.length > 0) {
  //         for (let index2 = 0; index2 < element.subMenus.length; index2++) {
  //           const element2 = element.subMenus[index2];
  //           if (!accessMenu?.includes(element2.link)) {
  //             element2.isView = false;
  //           }
  //         }
  //         if (element.subMenus.filter((item) => !item.isView).length === element.subMenus.length) {
  //           element.isView = false;
  //         }
  //       } else {
  //         if (!accessMenu?.includes(element.link)) {
  //           element.isView = false;
  //         }
  //       }
  //     }
  //     setMenus([...menus]);
  //   }
  // }, [location]);

  return (
    <SidebarWrapper style={{ ...props.style }}>
      <ProfileWrapper>
        <img src="/assets/admin/icons/ico_profile02.svg" alt="" />
        <SidebarPretendardText
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.042px",
            cursor: "unset",
          }}
        >
          {sessionStorage.getItem("name") || "관리자 이메일"}
        </SidebarPretendardText>
      </ProfileWrapper>
      <NavListWrapper>
        {menus.map((item, index) => {
          return (
            <div key={index}>
              {item.isView ? (
                <NavWrapper
                  onClick={() => {
                    if (item.subMenus.length > 0) {
                      isOpen === item.link ? setIsOpen(0) : setIsOpen(item.link);
                    } else {
                      if (item.link === route.os_pick) {
                        setOsTab("NONE");
                      }
                      navigate(item.type === "noList" ? item.link : item.link + `/1`);
                      sessionStorage.removeItem("tab");
                    }
                  }}
                >
                  <SidebarPretendardText
                    style={{
                      color: `${path?.includes(item.link) ? "#27BEFF" : ""}`,
                    }}
                  >
                    {item.title}
                  </SidebarPretendardText>
                  {item.subMenus.length > 0 ? (
                    <img
                      src={`/assets/admin/icons/ico_sidebar_${
                        isOpen === item.link || path?.includes(item.link) ? "minus" : "plus"
                      }.svg`}
                      alt=""
                    />
                  ) : null}
                </NavWrapper>
              ) : null}
              <SubmenuListWrapper>
                {item.subMenus.length > 0
                  ? item.subMenus.map((item2, index2) => {
                      return path?.includes(item.link) || isOpen === item.link ? (
                        <React.Fragment key={index2}>
                          {item2.isView ? (
                            <div>
                              <SubmenuWrapper
                                onClick={() => {
                                  if (
                                    item2.link === route.member_child ||
                                    item2.link === route.service_alarm
                                  ) {
                                    setFilterName("");
                                    setTab("NONE");
                                  }
                                  navigate(item2.link + `/1`);
                                  sessionStorage.removeItem("tab");
                                }}
                                style={{
                                  backgroundColor: `${
                                    path?.includes(item2.link) ? "#ffffff10" : ""
                                  }`,
                                  cursor: "pointer",
                                }}
                              >
                                <SubPretendardText
                                  style={{
                                    color: `${path?.includes(item2.link) ? "#FFF" : ""}`,
                                  }}
                                >
                                  {item2.title}
                                </SubPretendardText>
                              </SubmenuWrapper>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ) : null;
                    })
                  : null}
              </SubmenuListWrapper>
            </div>
          );
        })}
      </NavListWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;

const SidebarWrapper = styled.div.attrs((props) => {})`
  width: 265px;
  min-height: 100vh;
  height: auto;
  background-color: #2e313e;
  padding-top: 83px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 5;
`;

const ProfileWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 58px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NavListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const NavWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 25px;
  cursor: pointer;
`;

const SidebarPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #f2f4f5;
  font-size: 18px;
  font-weight: 600;
  line-height: 32.5px;
  letter-spacing: -0.054px;
  cursor: pointer;
`;

const SubmenuListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13.5px;
`;

const SubmenuWrapper = styled.div.attrs((props) => {})`
  width: 236px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 14px 25px;
  border-radius: 8px;
`;

const SubPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #778088;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  cursor: pointer;
`;
