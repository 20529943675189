import React from "react";
import styled from "styled-components";
import PretendardText from "../../component/atoms/text/pretendardText";

function Modal({ title, text, setModalOpen, onClick }) {
  return (
    <ModalWrapper>
      <ModalBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ModalTitle>{title}</ModalTitle>
          {text ? <ModalText>{text}</ModalText> : ""}
        </div>
        <ModalBtnWrapper>
          <ModalBtn onClick={onClick}>확인</ModalBtn>
          <CancelBtn onClick={() => setModalOpen(false)}>취소</CancelBtn>
        </ModalBtnWrapper>
      </ModalBox>
    </ModalWrapper>
  );
}

export default Modal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 394px;
  min-height: 173px;
  height: auto;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
`;

const ModalTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  color: #262c31;
  text-align: center;
`;

const ModalText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #808991;
  text-align: center;
  margin-top: 6px;
`;

const ModalBtnWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const CancelBtn = styled.button.attrs((props) => {})`
  width: 76px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #279eff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  border-radius: 100px;
`;

const ModalBtn = styled.button.attrs((props) => {})`
  width: 76px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #636c73;
  background: #f2f4f5;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  border-radius: 100px;
`;
