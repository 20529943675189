import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { nullCheck } from "../../util/check";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import ImgPreview from "../../component/molecules/imgPreview";
import ImgInput from "../../component/atoms/input/imgInput";
import { fileUpload } from "../../util/file-management";
import OsPickApi from "../../api/osPickApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Radio from "../../component/atoms/input/radio";
import PretendardText from "../../component/atoms/text/pretendardText";
import CommonApi from "./../../api/commonApi";

function OsPickEdit(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [number, setNumber] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");
  const [pickType, setPickType] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState("");
  const [deleteFileFlag, setDeleteFileFlag] = useState(false);

  const quillRef = useRef();

  const handleImage = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      if (input.files != null && input.files[0] != null) {
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i];
          // multer에 맞는 형식으로 데이터 만들어준다.

          // 백엔드 multer라우터에 이미지를 보낸다.
          try {
            const result = await fileAddFunc(file);
            const IMG_URL = result;
            // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
            // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
            // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

            // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
            const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
            // 1. 에디터 root의 innerHTML을 수정해주기
            // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
            // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
            // editor.root.innerHTML =
            //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

            // 2. 현재 에디터 커서 위치값을 가져온다
            const range = editor.getSelection();
            // 가져온 위치에 이미지를 삽입한다
            editor.insertEmbed(range.index, "image", IMG_URL);
          } catch (error) {
            errorHandler(error);
          }
        }
      }
    });
  };

  //이미지 모듈
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          ["image"],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: handleImage,
        },
      },
    };
  }, []);

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "image"];

  const fileAddFunc = async (file) => {
    try {
      const response = await CommonApi.FileAdd(file);

      return response.data.data.fileUrl;
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await OsPickApi.GetById(id)).data.data;

      setNumber(response.number);
      setTitle(response.title);
      setContent(response.content);
      setLink(response.link);
      setPickType(response.pickType);
      setFileUrl(response.fileUrl);
    } catch (error) {
      errorHandler(error);
    }
  };

  async function saveOsPick() {
    await saveFunc();
    setLoading(false);
  }

  const saveFunc = async () => {
    try {
      setSaveModalOpen(false);
      setLoading(true);
      const formData = new FormData();
      formData.append("number", number);
      formData.append("title", title);
      formData.append("link", link);
      formData.append("deleteFileFlag", deleteFileFlag);
      if (content) {
        formData.append("content", content);
      }
      formData.append("pickType", pickType);
      if (file) {
        formData.append("file", file);
      }

      await OsPickApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading-wrapper">
          <div className="loading-spinner-wrapper">
            <div className="loading-spinner">
              <img src="/assets/admin/icons/loading-spinner.svg" alt="" />
              <PretendardText style={{ fontSize: "14px", fontWeight: "700", color: "#fff" }}>
                잠시만 기다려주세요
              </PretendardText>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>추가 또는 수정</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>번호</DetailFormTitle>
                <MainInput
                  type={"number"}
                  placeholder={"번호를 입력해주세요"}
                  value={number}
                  onChange={(e) => setNumber(String(e.target.value))}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>구분</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ALL"}
                    valueText={"전체"}
                    name={"type"}
                    checked={pickType === "ALL"}
                    onChange={() => setPickType("ALL")}
                  />
                  <Radio
                    id={"PARENT"}
                    valueText={"보호자"}
                    name={"type"}
                    checked={pickType === "PARENT"}
                    onChange={() => setPickType("PARENT")}
                  />
                  <Radio
                    id={"CHILD"}
                    valueText={"자녀"}
                    name={"type"}
                    checked={pickType === "CHILD"}
                    onChange={() => setPickType("CHILD")}
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>게시물 썸네일</DetailFormTitle>
                {fileUrl ? (
                  <ImgPreview
                    fileUrl={fileUrl}
                    onClick={() => {
                      setFile("");
                      setFileUrl("");
                      setDeleteFileFlag(true);
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"PopupImg"}
                    accept={"image/*"}
                    onChange={(e) => {
                      fileUpload(e, setFileUrl, setFile);
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시물 제목</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"게시물제목"}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>게시물 본문</DetailFormTitle>
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  content-type="html"
                  style={{ width: "744px", height: "100%" }}
                  modules={modules}
                  formats={formats}
                  value={content}
                  onChange={setContent}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>관련 링크(선택)</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"https://"}
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (
                  nullCheck(title) ||
                  nullCheck(fileUrl) ||
                  nullCheck(pickType) ||
                  nullCheck(number)
                ) {
                  return;
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveOsPick}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default OsPickEdit;
