const prefix = "";

export const route = {
  default: prefix,
  login: prefix + "/login",
  join: prefix + "/join",
  join_complete: prefix + "/join/complete",

  // // 홈
  home: prefix + "/home",

  //사용자 관리
  member: prefix + "/member",
  member_parents: prefix + "/member/parents",
  member_parents_detail: prefix + "/member/parents/detail",
  member_parents_edit: prefix + "/member/parents/edit",
  member_child: prefix + "/member/child",
  member_child_detail: prefix + "/member/child/detail",
  member_child_edit: prefix + "/member/child/edit",
  member_leave: prefix + "/member/leave",

  //O'S PICK 관리
  os_pick: prefix + "/os_pick",
  os_pick_detail: prefix + "/os_pick_detail",
  os_pick_add: prefix + "/os_pick_add",
  os_pick_edit: prefix + "/os_pick_edit",

  //학원 관리
  academy: prefix + "/academy",
  academy_detail: prefix + "/academy/detail",
  academy_add: prefix + "/academy/add",
  academy_edit: prefix + "/academy/edit",

  //서비스 관리
  service: prefix + "/service",
  service_alarm: prefix + "/service/alarm",
  service_alarm_detail: prefix + "/service/alarm/detail",
  service_alarm_add: prefix + "/service/alarm/add",
  service_notice: prefix + "/service/notice",
  service_notice_detail: prefix + "/service/notice/detail",
  service_notice_add: prefix + "/service/notice/add",
  service_popup: prefix + "/service/popup",
  service_popup_detail: prefix + "/service/popup/detail",
  service_popup_edit: prefix + "/service/popup/edit",
  service_popup_add: prefix + "/service/popup/add",
  service_banner: prefix + "/service/banner",
  service_banner_detail: prefix + "/service/banner/detail",
  service_banner_edit: prefix + "/service/banner/edit",
  service_banner_add: prefix + "/service/banner/add",
  service_terms: prefix + "/service/terms",
  service_terms_detail: prefix + "/service/terms/detail",
  service_terms_edit: prefix + "/service/terms/edit",
  service_terms_add: prefix + "/service/terms/add",
  service_basic: prefix + "/service/basic",
  service_basic_edit: prefix + "/service/basic/edit",

  // 관리자회원 관리
  manager: prefix + "/manager",
  manager_detail: prefix + "/manager/detail",
  manager_edit_admin: prefix + "/manager/edit/admin",
  manager_edit_root: prefix + "/manager/edit/root",
};
