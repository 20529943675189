import React, { useEffect, useState } from "react";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/error-handler";
import { nullCheck } from "../../util/check";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import PostInput from "./../../component/atoms/input/postInput";
import AcademyApi from "../../api/academyApi";

function AcademyAdd(props) {
  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [academyName, setAcademyName] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");

  const saveFunc = async () => {
    try {
      const obj = {
        academyName: academyName,
        address: address,
        addressDetail: addressDetail,
      };

      await AcademyApi.Add(obj);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  function findAddrress() {
    let address = "";
    new window.daum.Postcode({
      oncomplete: function (data) {
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          address = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          address = data.jibunAddress;
        }
        setAddress(address);
      },
    }).open();
  }

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "386px" }}>
            <DetailPageTitle>추가 또는 수정</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>학원명</DetailFormTitle>
                <MainInput
                  type={"text"}
                  placeholder={"학원명을 입력해주세요"}
                  value={academyName}
                  onChange={(e) => setAcademyName(e.target.value)}
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>주소</DetailFormTitle>
                <div>
                  <PostInput
                    placeholder={"기본 주소"}
                    onClick={findAddrress}
                    address={address}
                    style={{ marginBottom: "8px" }}
                  />
                  <MainInput
                    type={"text"}
                    placeholder={"상세 주소"}
                    value={addressDetail}
                    onChange={(e) => setAddressDetail(e.target.value)}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"저장하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (nullCheck(academyName) || nullCheck(address)) {
                  toast(toastMsg.input_empty);
                  return;
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AcademyAdd;
