import { atom } from "recoil";

export const tabState = atom({
  key: "tabState",
  default: "NONE",
});

export const osTabState = atom({
  key: "osTabState",
  default: "NONE",
});
