import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

const DetailPageTitle = (props) => {
  return <DetailPageTitleWrapper {...props} />;
};

export default DetailPageTitle;

const DetailPageTitleWrapper = styled(PretendardText).attrs((props) => {})`
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: -0.054px;
  color: #262c31;
  margin-bottom: 30px;
`;
